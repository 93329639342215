import React, {
  FC,
  useContext,
  useEffect,
  useMemo,
  useState,
  useRef,
} from "react";
import { useMediaQuery } from "react-responsive";
import { isEmpty } from "lodash";
import { useSearchParams } from "react-router-dom";
import { getHostname } from "../../../../utils/location-utils";
import { useSnackbar } from "../../../../hooks/useSnackBar";

import { OrderContext } from "../../../../providers/OrderProvider";

import { Steps } from "../../../../enums/Steps";
import ErrorMessage from "../../../../enums/ErrorMessage";

import classNames from "classnames";
import classes from "../../ReturnsPage.module.scss";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleCheck } from "@fortawesome/free-solid-svg-icons";

import Modal from "@mui/material/Modal";
import LinearProgress from "@mui/material/LinearProgress";

import ReturnProduct from "../../../../components/Returns/ReturnProduct";
import Row from "../../../../components/Common/Row";
import Column from "../../../../components/Common/Column";
import Button from "../../../../components/Common/Button";
import Title from "../../../../components/Common/Title";
import Paragraph from "../../../../components/Common/Paragraph";
import Select from "../../../../components/Common/Select";
import { ReturnReasonValue } from "../../../../components/Common/ReturnReasonSelect";
import Textarea from "../../../../components/Common/Textarea";
import ReturnSummary from "../../../../components/Returns/ReturnSummary";
import useSettingsData from "../../../../hooks/useSettingsData";
import usePostRMAInformation from "../../../../services/usePostRMAInformation";
import useGetRMAInformation from "../../../../services/useGetRMAInformation";
import useInvoiceData from "../../../../hooks/useInvoiceData";
import { itemKey, isEnableItemsPerLine } from "../../../../utils/item-utils";
import {
  ReturnReasons,
  ReturnReasonsOptions,
} from "../../../../entities/ReturnReasons";

type Step2Props = {
  handleSetStep: (step: Steps) => void;
  returnReasonsOptions: ReturnReasonsOptions[];
};

export type ProductForm = {
  id: number;
  line: number;
  quantity: number;
  notes?: string;
  returnReason: ReturnReasons;
  amount: number;
};

export type Acc = {
  [key: string]: number;
};

type Notes = {
  [id: string]: string;
};

export const Step2: FC<Step2Props> = ({
  handleSetStep,
  returnReasonsOptions,
}) => {
  const { selectedProductsToReturn, handleSetCurrentRma } =
    useContext(OrderContext);
  const { postRMAInformation } = usePostRMAInformation();
  const settings = useSettingsData();
  const [searchParams] = useSearchParams();
  const { getRMAInformationForm } = useGetRMAInformation();
  const { addSnack } = useSnackbar();
  const [anyErrorPresent, setAnyErrorPresent] = useState(false);
  const returnContentRef = useRef<HTMLDivElement | null>(null);
  const refundMethodField = settings?.custrecord_ucp_returns_refund_method;
  const isNotesMandatory = settings?.custrecord_ucp_returns_comments;
  const location = settings?.custrecord_ucp_return_location;
  const refundMethodOptions = refundMethodField?.split(",");

  const { invoice, customerInfo } = useInvoiceData();
  const [quantities, setQuantities] = useState(
    selectedProductsToReturn.reduce((acc: Acc = {}, product) => {
      acc[product?.[itemKey]] = product.quantity;
      return acc;
    }, {})
  );

  const [returnReason, setReturnReason] = useState<
    Record<string, ReturnReasonValue>
  >({});

  const [loading, setLoading] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [notes, setNotes] = useState<Notes>({});
  const [comments, setComments] = useState("");
  const [refundMethod, setRefundMethod] = useState("");
  const [refundMethodError, setRefundMethodError] = useState("");
  const [returnReasonError, setReturnReasonError] = useState("");
  const [notesError, setNotesError] = useState("");
  const [isKeepItem, setIsKeepItem] = useState(false);
  const [open, setOpen] = React.useState(false);

  const isMobile = useMediaQuery({
    query: `(max-width: ${classes.screenL})`,
  });

  const isCreditNotCharges =
    settings?.custrecord_ucp_credit_method_no_charges &&
    refundMethod === "Credit";

  let handlingFee: number = 0;

  if (returnReason && !isCreditNotCharges) {
    const maxFee = Math.max(
      ...Object.values(returnReason).map((reason) =>
        Number(reason?.custrecord_ucp_handling_cost)
      )
    );

    if (!isNaN(maxFee) && maxFee >= 0 && maxFee < Infinity) {
      handlingFee = maxFee * -1;
    }
  }

  const orderNumber = useMemo(
    () => searchParams.get("order-number") ?? undefined,
    [searchParams]
  );

  const labelOptions = returnReasonsOptions.map(
    (label: ReturnReasonsOptions) => label
  );

  const handleQuantityChange = (id: number, quantity: number) => {
    setQuantities((prevState) => ({
      ...prevState,
      [id]: quantity,
    }));
  };

  const handleReturnReasonsChange = (id: string, reason: ReturnReasonValue) => {
    setReturnReason((prevState) => ({
      ...prevState,
      [id]: reason,
    }));
  };

  const handleNotesChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    const id = parseInt(event.target.name.split("_")[1]);
    const notes = event.target.value;
    setNotes((prevState) => ({
      ...prevState,
      [id]: notes,
    }));
  };

  const handleCommentsChange = (
    event: React.ChangeEvent<HTMLTextAreaElement>
  ) => {
    setComments(event.target.value);
  };

  const handleRefundMethodChange = (id: string, value: string) => {
    setRefundMethod(value);
    if (!refundMethod) {
      setRefundMethodError("");
    }
  };

  const calculateAmountPerItem = (line: string, selectedQuantity: number) => {
    const product = selectedProductsToReturn.find(
      (product) => product?.[itemKey] === line
    );
    const price = product?.price ?? 0;
    const originalQuantity = product?.quantity ?? 1;
    return Number((price / originalQuantity) * selectedQuantity);
  };

  const productForm: ProductForm[] = Object.entries(quantities).map(
    ([id, quantity]) => ({
      id: parseInt(id),
      line: parseInt(id),
      amount: calculateAmountPerItem(id, quantity),
      quantity,
      returnReason: returnReason[id],
      notes: notes[id],
    })
  );

  const hasEmptyReturnReason = productForm.some((product) =>
    isEmpty(product.returnReason)
  );

  const hasEmptyNotes = productForm.some((product) => isEmpty(product.notes));

  const validateForm = () => {
    const errors = [];

    if (isEmpty(refundMethod)) {
      errors.push(ErrorMessage.RefundMethod);
    }

    if (hasEmptyReturnReason) {
      errors.push(ErrorMessage.ReturnReason);
    }

    if (hasEmptyNotes && isNotesMandatory) {
      errors.push(ErrorMessage.Notes);
    }

    return errors;
  };

  const handleSubmit = async () => {
    try {
      setLoading(true);
      const errors = validateForm();

      setRefundMethodError(
        errors.includes(ErrorMessage.RefundMethod)
          ? ErrorMessage.RefundMethod
          : ""
      );
      setReturnReasonError(
        errors.includes(ErrorMessage.ReturnReason)
          ? ErrorMessage.ReturnReason
          : ""
      );
      setNotesError(
        errors.includes(ErrorMessage.Notes) ? ErrorMessage.Notes : ""
      );

      setAnyErrorPresent(errors.length > 0);

      if (errors.length > 0) {
        setLoading(false);
        return;
      }

      try {
        const domain = getHostname(); // TODO: remove this, only for testing purposes'

        const oldItemList = productForm.map((product) => ({
          item: String(product.id),
          quantity: String(product.quantity),
          location: location ?? "1",
          custcol_ucp_return_comments: product.notes ?? "",
          custcol_ucp_return_reason: returnReason[product.id]?.id ?? "1",
          custcol_ucp_return_location_line: location ?? "",
          custcol_ucp_return_item_eligibility: "30", // TODO
          custcol_ucp_return_date: "5/1/2023", // TODO
          custcol_ucp_return_option: "1", // ????
          custcol_ucp_qty_returned: String(product.quantity),
        }));

        const itemList = productForm.map((product) => ({
          item: String(
            selectedProductsToReturn?.find(
              (selectedProduct) =>
                Number(selectedProduct.line) === product?.line
            )?.id ?? ""
          ),
          quantity: String(product.quantity),
          location: location ?? "1",
          custcol_ucp_return_comments: product.notes ?? "",
          custcol_ucp_return_reason: returnReason[product.line]?.id ?? "1",
          custcol_ucp_return_location_line: location ?? "",
          custcol_ucp_return_item_eligibility: "30", // TODO
          custcol_ucp_return_date: "5/1/2023", // TODO
          custcol_ucp_return_option: "1", // ????
          custcol_ucp_qty_returned: String(product.quantity),
          product_line: product?.line,
        }));

        const resCalculation = await postRMAInformation({
          autoApproveSetting:
            settings?.custrecord_ucp_returns_auto_approve ?? false,
          returnReason,
          productForm,
          itemKey,
          domain,
          customerId: customerInfo?.id ?? "",
          orderNumber: String(orderNumber),
          additionalComments: comments,
          refoundMethod: refundMethod,
          handlingFee: String(handlingFee),
          custrecord_ucp_commerce_platform:
            settings?.custrecord_ucp_commerce_platform,
          custrecord_ucp_order_number_field_id:
            settings?.custrecord_ucp_order_number_field_id,
          custrecord_ucp_handling_fee_item:
            settings?.custrecord_ucp_handling_fee_item,
          itemList: isEnableItemsPerLine ? itemList : oldItemList,
        });

        const resRMA = await getRMAInformationForm({
          orderNumber: invoice?.id ?? "",
          domain: getHostname(),
          zipCode: invoice?.shipzip ?? "",
        });
        handleSetCurrentRma(resRMA.results);
        const lastRMA = resRMA.results.results.length;
        const submittedRMA = resRMA.results.results[lastRMA - 1];
        setIsKeepItem(
          submittedRMA.items.some(
            (item) =>
              item.itemtype === "InvtPart" &&
              item.custcol_ucp_return_option === "Keep Item"
          )
        );
        if (resCalculation?.autoApprove) {
          handleSetStep(Steps.STEP3);
        } else {
          setOpen(true);
        }
      } catch (error) {
        addSnack({
          display: true,
          severity: "error",
          message: String(error),
        });
      } finally {
        setLoading(false);
        setSubmitted(true);
      }
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
      setSubmitted(false);
    }
  };

  const handleStatusPage = () => {
    handleSetStep(Steps.STEP3);
  };

  const onBackStep = () => {
    handleSetStep(Steps.STEP1);
  };

  useEffect(() => {
    const hasEmptyReason = productForm.some(
      (product) => !returnReason[product?.[itemKey]]
    );
    const hasEmptyNotes = productForm.some(
      (product) => !notes[product?.[itemKey]]
    );
    if (!hasEmptyNotes && isNotesMandatory) {
      setNotesError("");
    }
    if (!hasEmptyReason) {
      setReturnReasonError("");
    }
  }, [returnReason, productForm, isNotesMandatory, notes]);

  useEffect(() => {
    if (isMobile) {
      if (anyErrorPresent && returnContentRef.current) {
        returnContentRef.current.scrollIntoView({ behavior: "smooth" });
      }
    }
  }, [anyErrorPresent, isMobile]);

  const productFormProps = {
    quantities,
    returnReason,
    notes,
    handleQuantityChange,
    handleReturnReasonsChange,
    handleNotesChange,
    returnReasonError,
    notesError,
    labelOptions,
    isNotesMandatory,
  };

  return (
    <>
      <Row>
        <Column width={60}>
          <section className={classes.returnItems}>
            <div className={classes.returnWrapper}>
              <header>
                <Title kind="h2" size="s">
                  Selected Items
                </Title>
                <Paragraph size="xxs">
                  Please add the details of the reason you’re returning these
                  items.
                </Paragraph>
              </header>
              <div ref={returnContentRef} className={classes.returnContent}>
                {selectedProductsToReturn.map((product) => (
                  <ReturnProduct
                    props={productFormProps}
                    product={product}
                    key={product.id}
                  />
                ))}
              </div>
            </div>
          </section>
          {isMobile && (
            <div className={classes.refundMethodWrapper}>
              <div className={classes.refundMethod}>
                <span className={classes.refundMethodLabel}>
                  Refund Method*
                </span>
                <Select
                  options={refundMethodOptions?.map((option: string) => ({
                    label: option,
                    value: option,
                  }))}
                  onChange={handleRefundMethodChange}
                  className={classNames(
                    classes.refundMethodSelect,
                    `${refundMethodError ? classes.error : ""}`
                  )}
                />
                <small className={classes.stepError}>{refundMethodError}</small>
              </div>
            </div>
          )}
          <section className={classes.additionalComments}>
            <Textarea
              label="Additional comments about your return."
              placeholder="Let us know here what else we should know"
              onChange={handleCommentsChange}
            />
          </section>
          {!isMobile && (
            <section className={classes.disclaimerText}>
              <Paragraph size="xxxs">
                We'll send you return notifications to{" "}
                <span>{customerInfo?.email}</span>. Refunds will be refunded to
                your existing payment method or you will receive store credit if
                purchase wasa a gift.
              </Paragraph>
            </section>
          )}
        </Column>
        <Column width={40} className={classes.stickyContent}>
          <div className={classes.returnSummaryWrapper}>
            <ReturnSummary handlingFee={handlingFee} quantities={quantities} />
            {!isMobile && (
              <div className={classes.refundMethod}>
                <span className={classes.refundMethodLabel}>
                  Refund Method*
                </span>
                <Select
                  options={refundMethodOptions?.map((option: string) => ({
                    label: option,
                    value: option,
                  }))}
                  onChange={handleRefundMethodChange}
                  className={classNames(
                    classes.refundMethodSelect,
                    `${refundMethodError ? classes.error : ""}`
                  )}
                />
              </div>
            )}
          </div>
          {isMobile && (
            <section className={classes.disclaimerText}>
              <Paragraph size="xxxs">
                We'll send you return notifications to{" "}
                <span>{customerInfo?.email}</span>. Refunds will be refunded to
                your existing payment method or you will receive store credit if
                purchase wasa a gift.
              </Paragraph>
            </section>
          )}
          <div className={classes.stepButtons}>
            <div
              className={classNames(classes.stepButtonsWrapper, classes.step2)}
            >
              <div className={classes.submitWrapper}>
                <Button
                  onClick={handleSubmit}
                  kind="primary"
                  className={classes.continueButton}
                  disabled={loading || submitted}
                >
                  {loading
                    ? "Creating Return..."
                    : submitted
                    ? "Return Created"
                    : "Confirm Return"}
                </Button>
                {loading && !submitted && (
                  <div className={classes.progressBar}>
                    <LinearProgress
                      sx={{
                        backgroundColor: "#ffffff",
                        "& .MuiLinearProgress-bar": {
                          backgroundColor: "var(--headings-color);",
                        },
                      }}
                    />
                  </div>
                )}
              </div>
              <Button
                onClick={onBackStep}
                kind="secondary"
                className={classes.backButton}
              >
                Go Back
              </Button>
            </div>
            {!isMobile && (
              <div className={classes.stepErrors}>
                <small className={classes.stepError}>{refundMethodError}</small>
                <small className={classes.stepError}>{returnReasonError}</small>
                <small className={classes.stepError}>{notesError}</small>
              </div>
            )}
          </div>
        </Column>
      </Row>
      <Modal
        open={open}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <div className={classes.modal}>
          <FontAwesomeIcon icon={faCircleCheck} className={classes.iconCheck} />
          {isKeepItem ? (
            <>
              <Title kind="h3" size="l">
                Good News!
              </Title>
              <Paragraph className={classes.subTitle} size="xs">
                You don't need to ship your products.
              </Paragraph>
              <Paragraph size="xxs" className={classes.description}>
                Your return is now pending approval. We'll issue your refund or
                gift card once we process your return.
              </Paragraph>
            </>
          ) : (
            <>
              <Title kind="h3" size="l">
                Thank you!
              </Title>
              <Paragraph className={classes.subTitle} size="xs">
                Your return has been submitted.
              </Paragraph>
              <Paragraph size="xxs" className={classes.description}>
                Your return is now pending approval. We will email you within
                the next 1 - 2 business days for next steps.
              </Paragraph>
            </>
          )}
          <Button
            onClick={handleStatusPage}
            kind="secondary"
            className={classes.closeModalButton}
          >
            Check your return status
          </Button>
        </div>
      </Modal>
    </>
  );
};
