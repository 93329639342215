import { useCallback, useState } from "react";
import Cookies from "js-cookie";

import AuthenticationError from "../errors/AuthenticationError";
import UnexpectedError from "../errors/UnexpectedError";
import useAuthService from "../services/useAuthService";
import { getHostname } from "../utils/location-utils";
import { usePortalLookAndFeelStorage } from "../hooks/usePortalLookAndFeelStorage";

const DAYS_TO_EXPIRE_CREDENTIALS = 1;

const useAuth = () => {
  const { authenticateService } = useAuthService();

  const [error, setError] = useState<Error | null>(null);
  // const [application, setApplication] = useState<ApplicationOptions>();
  const [isLoading, setIsLoading] = useState(false);

  const application =
    Cookies.get("application") &&
    JSON.parse(String(Cookies.get("application")));

  const credential =
    Cookies.get("credential") && JSON.parse(String(Cookies.get("credential")));

  const { hasPortalLookAndFeelFromLocalStorage } =
    usePortalLookAndFeelStorage();

  const authenticate = useCallback(async () => {
    try {
      setError(null);
      if (!hasPortalLookAndFeelFromLocalStorage) {
        setIsLoading(true);
      }
      const { application: applicationData, token } = await authenticateService(
        getHostname()
      );

      if (!token) {
        throw new AuthenticationError();
      }

      Cookies.set("credential", JSON.stringify({ token }), {
        expires: DAYS_TO_EXPIRE_CREDENTIALS,
      });
      Cookies.set("application", JSON.stringify(applicationData), {
        expires: DAYS_TO_EXPIRE_CREDENTIALS,
      });
    } catch (e) {
      const newError = new UnexpectedError(
        "Unexpected error",
        "An unexpected error occurred, please check the url or try again later",
        e as Error
      );
      const error = e as Error;
      setError(error ?? newError);
    } finally {
      setIsLoading(false);
    }
  }, [
    authenticateService,
    setError,
    setIsLoading,
    hasPortalLookAndFeelFromLocalStorage,
  ]);

  return {
    credential,
    application,
    isLoading,
    error,
    authenticate,
  };
};

export default useAuth;
