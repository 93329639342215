import { httpsCallable, HttpsCallable } from "firebase/functions";
import { useCallback, useMemo } from "react";

import useFunctionsService from "./useFunctionsService";
// import ShipmentNotification from "../entities/ShipmentNotification";
import useAuth from "../hooks/useAuth";
import { InvoiceData } from "../entities/InvoiceInfo";

const useGetInvoiceInformation = () => {
  const { functions } = useFunctionsService();
  const { credential } = useAuth();
  const token = useMemo(() => credential?.token, [credential]);

  const getStatusOrderFormFunction: HttpsCallable<
    {
      token: string | undefined;
      orderNumber: string;
      zipCode: string;
      domain: string;
    },
    InvoiceData
  > = useMemo(
    () => httpsCallable(functions, "unlockreturns-getInvoiceInformation"),
    [functions]
  );

  const getStatusOrderForm = useCallback(
    async (statusOrderForm: {
      orderNumber: string;
      zipCode: string;
      domain: string;
    }) => {
      try {
        const result = await getStatusOrderFormFunction({
          token,
          ...statusOrderForm,
        });

        return result.data;
      } catch (e) {
        console.error(e);
        throw new Error(
          "Unexpected error while authenticating, try again later!"
        );
      }
    },
    [token, getStatusOrderFormFunction]
  );
  return { getStatusOrderForm };
};

export default useGetInvoiceInformation;
