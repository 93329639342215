import classNames from "classnames";
import { createElement } from "react";

import classes from "./Title.module.scss";

export type TitleKind = "h1" | "h2" | "h3" | "h4";
export type TitleSize = "xl" | "l" | "m" | "s" | "xs" | "xxs";
export type TitleProps = {
  children: React.ReactNode;
  className?: string;
  size?: TitleSize;
  kind?: TitleKind;
  [key: string]: any;
};

const Title = ({
  children,
  className = "",
  size = "xl",
  kind = "h1",
  ...props
}: TitleProps) =>
  createElement(
    kind,
    {
      className: classNames(classes.title, classes[size], className),
      ...props,
    },
    children
  );

export default Title;
