import { BrowserRouter, Routes, Route } from "react-router-dom";

import Path from "../enums/Path";
import Home from "../pages/Home";
import ReturnsPage from "../pages/ReturnsPage";
import NotFound from "../pages/404";

const Router = () => (
  <BrowserRouter>
    <Routes>
      <Route element={<Home />} path={Path.Home} />
      <Route element={<ReturnsPage />} path={Path.ReturnsPage} />
      <Route element={<NotFound />} path="*" />
    </Routes>
  </BrowserRouter>
);

export default Router;
