import classes from "./ReturnStatus.module.scss";

import StatusProgress from "../StatusProgress";
import Title from "../../Common/Title";
import Paragraph from "../../Common/Paragraph";
import ExternalLink from "../../Common/ExternalLink";
import { PDFLabel, RMAData } from "../../../entities/RMAData";
import Skeleton from "@mui/material/Skeleton";

type ReturnStatusProps = {
  rmaData: RMAData;
  pdfLabelData: PDFLabel | null;
  isLabelInfoCreating: boolean;
  isFetchingLabels: boolean;
};

const ReturnStatus: React.FC<ReturnStatusProps> = ({
  rmaData,
  pdfLabelData,
  isLabelInfoCreating,
  isFetchingLabels,
}) => {
  const shippingStatus = rmaData.custbody_ucp_return_shipping_status;

  return (
    <>
      <section className={classes.returnShipment}>
        {isFetchingLabels || isLabelInfoCreating ? (
          <div className={classes.skeletonWrapper}>
            <div className={classes.skeletonCol}>
              <Skeleton variant="rounded" className={classes.skeletonTitle} />
              <Skeleton variant="text" className={classes.skeletonParagraph} />
            </div>
            <div className={classes.skeletonCol}>
              <div className={classes.skeletonWrapper}>
                <Skeleton
                  variant="text"
                  className={classes.skeletonParagraph}
                />
                <Skeleton
                  variant="circular"
                  className={classes.skeletonCircle}
                />
              </div>
            </div>
          </div>
        ) : (
          <div className={classes.returnShipmentWrapper}>
            <div>
              <Title kind="h3" size="s">
                Shipping Status
              </Title>
              <ExternalLink
                kind="primary"
                to={pdfLabelData?.custrecord_ucp_public_url}
                target="_blank"
                className={classes.trackingURL}
              >
                View Tracking
              </ExternalLink>
            </div>
            <div>
              <div className={classes.returnShipmentStatus}>
                <Paragraph>
                  {shippingStatus === "" ? "New" : shippingStatus}
                </Paragraph>
                <StatusProgress status={shippingStatus} />
              </div>
            </div>
          </div>
        )}
      </section>
    </>
  );
};

export default ReturnStatus;
