import { httpsCallable, HttpsCallable } from "firebase/functions";
import { useCallback, useMemo } from "react";

import useFunctionsService from "./useFunctionsService";

type RMARequestBody = {
  domain: string;
  rmaID: string;
};

const useCancelReturnAuthorization = () => {
  const { functions } = useFunctionsService();

  const cancelReturnAuthorizationFunction: HttpsCallable<
    RMARequestBody,
    {
      result: any;
    }
  > = useMemo(
    () => httpsCallable(functions, "unlockreturns-postCancelReturnAuthorization"),
    [functions]
  );

  const cancelReturnAuthorization = useCallback(
    async (rmaRequestBody: RMARequestBody) => {
      try {
        await cancelReturnAuthorizationFunction({
          ...rmaRequestBody,
        });
      } catch (e) {
        console.error(e);
        throw new Error(
          "Unexpected error while processing the return, try again later!"
        );
      }
    },
    [cancelReturnAuthorizationFunction]
  );

  return { cancelReturnAuthorization };
};

export default useCancelReturnAuthorization;
