import React, { ChangeEvent, useState } from "react";

import classNames from "classnames";
import classes from "./Select.module.scss";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";

interface Option {
  label: string;
  value: string;
}

interface SelectProps {
  options?: Option[];
  defaultValue?: string;
  id?: string;
  name?: string;
  label?: string;
  className?: string;
  onChange?: (id: string, value: string) => void;
}

const Select: React.FC<SelectProps> = ({
  options = [],
  defaultValue,
  id,
  name,
  label,
  className,
  onChange,
}) => {
  const [, setValue] = useState(defaultValue);
  const handleChange = (event: ChangeEvent<HTMLSelectElement>) => {
    const newValue = event.target.value;
    const newId = event.target.id.split("_")[1];
    setValue(() => newValue);
    if (onChange) {
      const selectedOption = options.find(
        (option) => option.value === newValue
      );
      onChange(newId, selectedOption?.value ?? "");
    }
  };

  const updatedOptions = [
    { label: "- Select An Option -", value: "" },
    ...options,
  ];

  return (
    <div className={classNames(classes.select, className)}>
      {label && <label>{label}</label>}
      <div className={classes.selectWrapper}>
        <select onChange={handleChange} id={id} name={name}>
          {updatedOptions.map((option) => (
            <option key={option.value} value={option.value}>
              {option.label}
            </option>
          ))}
        </select>
        <FontAwesomeIcon icon={faChevronDown} />
      </div>
    </div>
  );
};

export default Select;
