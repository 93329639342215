import React, { useState } from "react";
import axios from "axios";
import { useSnackbar } from "../../../hooks/useSnackBar";
import classNames from "classnames";
import classes from "../PrintLabelModal/PrintLabelModal.module.scss";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";

import Modal from "@mui/material/Modal";
import Button from "../../Common/Button";

import { Document, Page, pdfjs } from "react-pdf";
import Title from "../../Common/Title";
import LoadSpinner from "../../Common/LoadSpinner";

type PrintLabelModalProps = {
  open: boolean;
  pdfURL: string;
  onClose: () => void;
};

const PrintLabelModal: React.FC<PrintLabelModalProps> = ({
  open,
  onClose,
  pdfURL,
}) => {
  pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

  const url = pdfURL;

  const [, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [zoom, setZoom] = useState(false);
  const onDocumentLoadSuccess = (numPages: any) => {
    setNumPages(numPages);
    setPageNumber(1);
  };
  const { addSnack } = useSnackbar();

  const downloadPdf = () => {
    axios
      .get(url, { responseType: "blob" })
      .then((response) => {
        try {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", "print-label.pdf");
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        } catch (error) {
          console.error(error);
          addSnack({
            display: true,
            severity: "error",
            message: "Unexpected error. Please try again.",
          });
        }
      })
      .catch((error) => {
        console.error(error);
        addSnack({
          display: true,
          severity: "error",
          message: "Unexpected error. Please try again.",
        });
      });
  };

  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <div className={classNames(classes.modal, classes.printLabelModal)}>
        <Title kind="h3">Print Label</Title>
        <div
          className={classes.pdfWrapper}
          style={{ cursor: zoom ? "zoom-out" : "zoom-in" }}
          onClick={() => setZoom(!zoom)}
        >
          <Document
            file={url}
            onLoadSuccess={onDocumentLoadSuccess}
            loading={<LoadSpinner />}
          >
            <Page
              pageNumber={pageNumber}
              className={zoom ? classes.zoom : undefined}
              renderTextLayer={false}
            />
          </Document>
        </div>
        <Button
          onClick={downloadPdf}
          kind="primary"
          className={classes.downloadPdfButton}
        >
          Download Label
        </Button>
        <Button
          kind="secondary"
          className={classes.closeModalButton}
          onClick={onClose}
        >
          Close
        </Button>
      </div>
    </Modal>
  );
};

export default PrintLabelModal;
