import useInvoiceData from "../../../hooks/useInvoiceData";
import classes from "./CustomerInfo.module.scss";

import Title from "../../Common/Title";
import Paragraph from "../../Common/Paragraph";

import { formatName, formatPhoneNumber } from "../../../utils/formatter-utils";

const CustomerInfo = () => {
  const { customerInfo } = useInvoiceData();

  const shipAddress = customerInfo?.shipAddress.split("\n").slice(1);

  return (
    <div className={classes.customerData}>
      <Title kind="h4" size="xxs">
        {formatName(String(customerInfo?.name)) ?? "-"}
      </Title>
      {shipAddress?.map((line, index) => (
        <div key={index}>
          <Paragraph size="xxxs">{line}</Paragraph>
        </div>
      ))}
      <Paragraph size="xxxs">{customerInfo?.email ?? "-"}</Paragraph>
      {customerInfo?.phone && (
        <Paragraph size="xxxs">
          {formatPhoneNumber(String(customerInfo?.phone))}
        </Paragraph>
      )}
    </div>
  );
};

export default CustomerInfo;
