import { FC } from "react";
import Header from "../Header";
import FooterContainer from "../FooterContainer";
import LayoutContainer from "../LayoutContainer";

type LayoutStepsType = {
  children: React.ReactNode;
};

const LayoutSteps: FC<LayoutStepsType> = ({ children }) => {
  return (
    <>
      <Header />
      <LayoutContainer>{children}</LayoutContainer>
      <FooterContainer />
    </>
  );
};

export default LayoutSteps;
