enum ShipmentStatus {
  New = "",
  Unknown = "1",
  Pre_Transit = "2",
  In_Transit = "3",
  Out_For_Delivery = "4",
  Delivered = "5",
  Available_For_Pickup = "6",
  Return_To_Sender = "7",
  Failure = "8",
  Cancelled = "9",
  Error = "10",
  StorePickup = "12"
}

export default ShipmentStatus;

export const stringify = (status: ShipmentStatus) => {
  switch (status) {
    case ShipmentStatus.StorePickup:
      return "Store Pickup";
    case ShipmentStatus.New:
      return "New";
    case ShipmentStatus.Unknown:
      return "Unknown";
    case ShipmentStatus.Pre_Transit:
      return "Pre Transit";
    case ShipmentStatus.In_Transit:
      return "In Transit";
    case ShipmentStatus.Out_For_Delivery:
      return "Out for Delivery";
    case ShipmentStatus.Delivered:
      return "Delivered";
    case ShipmentStatus.Available_For_Pickup:
      return "Available for Pickup";
    case ShipmentStatus.Return_To_Sender:
      return "Return to Sender";
    case ShipmentStatus.Failure:
      return "Failure";
    case ShipmentStatus.Cancelled:
      return "Cancelled";
    case ShipmentStatus.Error:
      return "Error";
    default:
      throw Error("Shipment status did not match.");
  }
};
