import { FC } from "react";
import classes from "./Column.module.scss";
import classNames from "classnames";

type ColumnType = {
  children: React.ReactNode;
  className?: string;
  width: number;
};

const Column: FC<ColumnType> = ({ children, className, width }) => {
  return (
    <div
      className={classNames(classes.column, `${className ?? ""}`)}
      style={{ flex: `0 0 ${width}%` }}
    >
      {children}
    </div>
  );
};

export default Column;
