import React from "react";

import classNames from "classnames";
import classes from "./Textarea.module.scss";

type TextareaProps = {
  label?: string;
  className?: string;
  id?: string;
  name?: string;
  placeholder?: string;
  onChange: (event: React.ChangeEvent<HTMLTextAreaElement>) => void;
};

const Textarea: React.FC<TextareaProps> = ({
  label,
  className,
  id,
  name,
  placeholder,
  onChange,
}) => {
  return (
    <div className={classNames(classes.textarea, className)}>
      {label && <label>{label}</label>}
      <textarea
        id={id}
        name={name}
        onChange={onChange}
        placeholder={placeholder}
        rows={4}
      />
    </div>
  );
};

export default Textarea;
