import {
  useState,
  createContext,
  useCallback,
  ReactNode,
  FC,
  useMemo,
} from "react";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import classes from "../ToastProvider/ToastProvider.module.scss";

export const ToastContext = createContext({
  // eslint-disable-next-line
  addSnack: (status: SubmissionStatus) => {},
  handleCloseSnackbar: () => {},
});

export interface SubmissionStatus {
  display: boolean;
  message: string;
  severity?: "success" | "error" | "info";
  vertical?: "top" | "bottom";
  horizontal?: "right" | "left" | "center";
  className?: string;
}

type ToastProviderType = {
  children: ReactNode;
};

const ToastProvider: FC<ToastProviderType> = ({ children }) => {
  const [submissionStatus, setSubmissionStatus] = useState<SubmissionStatus>({
    display: false,
    message: "",
  });

  const addSnack = useCallback(
    (status: SubmissionStatus) => {
      setSubmissionStatus({ ...status });
    },
    [setSubmissionStatus]
  );

  const handleCloseSnackbar = useCallback(() => {
    setSubmissionStatus({ ...submissionStatus, display: false });
  }, [submissionStatus, setSubmissionStatus]);

  const providerValue = useMemo(
    () => ({ addSnack, handleCloseSnackbar }),
    [addSnack, handleCloseSnackbar]
  );

  return (
    <ToastContext.Provider value={providerValue}>
      <Snackbar
        open={submissionStatus.display}
        autoHideDuration={11000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{
          vertical: submissionStatus.vertical || "bottom",
          horizontal: submissionStatus.horizontal || "center",
        }}
        className={
          submissionStatus.className ? classes[submissionStatus.className] : ""
        }
        sx={{ boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.2)" }}
      >
        <Alert
          onClose={handleCloseSnackbar}
          severity={submissionStatus.severity}
          className={classes.alertSnackbar}
          sx={{
            width: "100%",
          }}
        >
          {submissionStatus.message}
        </Alert>
      </Snackbar>
      {children}
    </ToastContext.Provider>
  );
};

export default ToastProvider;
