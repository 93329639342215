export enum Status {
  Cancelled = "cancelled",
  Closed = "closed",
  PendingApproval = "pendingApproval",
  Refunded = "refunded",
  PendingRefund = "pendingRefund",
  PendingReceipt = "pendingReceipt",
  PartiallyReceived = "partiallyReceived",
  PartiallyReceivedPendingRefund = "pendingRefundPartReceived",
}
