export const formatPhoneNumber = (phoneNumber: string): string => {
  const cleaned = ("" + phoneNumber).replace(/\D/g, "");

  if (phoneNumber.length === 10) {
    const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      return "(" + match[1] + ") " + match[2] + "-" + match[3];
    }
  }

  if (phoneNumber.length === 11) {
    const match = cleaned.match(/^(\d{1})(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      return (
        "+" + match[1] + " (" + match[2] + ") " + match[3] + "-" + match[4]
      );
    }
  }

  return "-";
};

export const formatName = (name: string): string => name.replace(/\d+/g, "");

export const formatAmount = (amount: number): string => {
  return amount.toLocaleString("en-US", { style: "currency", currency: "USD" });
};

export const formatPrice = (value?: number) => {
  if (value == null) return "";
  const hasDecimals = value % 1 !== 0;
  return hasDecimals ? value.toFixed(2) : Math.trunc(value).toString();
};
