import classNames from "classnames";
import { v4 as uuidv4 } from "uuid";
import { useMediaQuery } from "react-responsive";

import classes from "./FooterContainer.module.scss";
import Title from "../Title";
import Paragraph from "../Paragraph";
import ExternalLink from "../ExternalLink";
import MinorFooter from "../MinorFooter";
import Rights from "../Rights";
import usePortalLookAndFeel from "../../../hooks/usePortalLookAndFeel";
import LayoutContainer from "../LayoutContainer";

const FooterContainer = () => {
  const { portalLookAndFeel } = usePortalLookAndFeel();

  const isMobile = useMediaQuery({
    query: `(max-width: ${classes.screenXl})`,
  });

  return (
    <>
      <div className={classNames(classes.footerContainer)}>
        <LayoutContainer>
          <div className={classNames(classes.principal)}>
            <div className={classNames(classes.imgContainer)}>
              <img
                src={portalLookAndFeel.footerImage}
                alt={`${portalLookAndFeel.companyName} footer logo`}
              />
              {portalLookAndFeel.slogan && (
                <div className={classes.slogan}>
                  <Title
                    kind="h2"
                    size="s"
                    style={{ color: portalLookAndFeel.textColor }}
                  >
                    {portalLookAndFeel.slogan}
                  </Title>
                </div>
              )}
            </div>
            {portalLookAndFeel.footerSections && (
              <div className={classNames(classes.linksList)}>
                {portalLookAndFeel.footerSections.map((section) => (
                  <div key={uuidv4()} className={classNames(classes.column)}>
                    <Title
                      size="m"
                      className={classNames(
                        classes.column,
                        classes.sectionTitle
                      )}
                    >
                      {section.title}
                    </Title>
                    {section.links.map((linkObject) => (
                      <ExternalLink
                        key={uuidv4()}
                        to={linkObject.url}
                        className={classNames(classes.column)}
                        target="_blank"
                      >
                        {linkObject.text}
                      </ExternalLink>
                    ))}
                  </div>
                ))}
              </div>
            )}
          </div>
          <div className={classNames(classes.footer)}>
            {!isMobile && (
              <Paragraph size="s" className={classes.rights}>
                <Rights />
              </Paragraph>
            )}
            {portalLookAndFeel.footerLinks && (
              <div className={classNames(classes.column)}>
                {portalLookAndFeel.footerLinks.map((linkObject) => (
                  <ExternalLink
                    key={uuidv4()}
                    to={linkObject.url}
                    className={classNames(classes.link, classes.bottomLink)}
                    target="_blank"
                  >
                    {linkObject.text}
                  </ExternalLink>
                ))}
              </div>
            )}
          </div>
        </LayoutContainer>
      </div>
      {isMobile && <MinorFooter />}
    </>
  );
};

export default FooterContainer;
