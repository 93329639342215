import React, { useEffect, useRef } from "react";

import classNames from "classnames";
import classes from "./QuantityInput.module.scss";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMinus, faPlus } from "@fortawesome/free-solid-svg-icons";

type QuantityInputProps = {
  value: number;
  className?: string;
  label?: string;
  id: string;
  name?: string;
  productId: number;
  quantity: number;
  max: number;
  onValueChange: (productId: number, quantity: number) => void;
};

const QuantityInput: React.FC<QuantityInputProps> = ({
  value,
  label,
  id,
  name,
  className,
  onValueChange,
  productId,
  quantity,
  max,
}) => {
  const increaseButtonRef = useRef<HTMLButtonElement>(null);
  const decreaseButtonRef = useRef<HTMLButtonElement>(null);

  const handleIncrease = () => {
    if (quantity < max) {
      onValueChange(productId, quantity + 1);
    }
  };

  const handleDecrease = () => {
    if (quantity > 1) {
      onValueChange(productId, quantity - 1);
    }
  };

  useEffect(() => {
    if (quantity === max) {
      increaseButtonRef.current?.setAttribute("disabled", "disabled");
    } else {
      increaseButtonRef.current?.removeAttribute("disabled");
    }
    if (quantity <= 1) {
      decreaseButtonRef.current?.setAttribute("disabled", "disabled");
    } else {
      decreaseButtonRef.current?.removeAttribute("disabled");
    }
  });

  return (
    <div className={classNames(classes.quantityInput, className)}>
      {label && <label className={classes.label}>{label}</label>}
      <div className={classes.quantityInputWrapper}>
        <button
          ref={decreaseButtonRef}
          className={classes.decreaseButton}
          onClick={handleDecrease}
        >
          <FontAwesomeIcon icon={faMinus} />
        </button>
        <input
          type="number"
          name={name}
          id={id}
          value={quantity}
          max={quantity}
          className={classes.input}
          readOnly
        />
        <button
          ref={increaseButtonRef}
          className={classes.increaseButton}
          onClick={handleIncrease}
        >
          <FontAwesomeIcon icon={faPlus} />
        </button>
      </div>
    </div>
  );
};

export default QuantityInput;
