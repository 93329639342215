import { useCallback, useMemo } from "react";
import { HttpsCallable, httpsCallable } from "firebase/functions";

import useFunctionsService from "./useFunctionsService";
import { ApplicationOptions } from "../entities/Application";

const useAuthService = () => {
  const { functions } = useFunctionsService();

  const authenticateFunction: HttpsCallable<
    { domain: string; orderNumber?: string; zipCode?: string },
    {
      statusCode: number;
      message: string;
      token: string;
      application: ApplicationOptions;
    }
  > = useMemo(
    () => httpsCallable(functions, "unlockreturns-authenticate"),
    [functions]
  );

  const authenticateService = useCallback(
    async (domain: string) => {
      let rest;
      try {
        const { ...restProp } = (
          await authenticateFunction({
            domain,
          })
        ).data;
        rest = restProp;
      } catch (e) {
        console.error(e);
        throw new Error(
          "Unexpected error while authenticating, try again later!"
        );
      }

      if (rest.statusCode === 404) {
        throw new Error(
          rest?.message
            ? rest.message
            : "Sorry, we were unable to locate your shipment. Please ensure your order number and zip code are correct. If you continue to experience difficulties, it might be that your order is still being processed and not yet shipped."
        );
      }

      if (rest.statusCode !== 200) {
        throw new Error("An unexpected error occurred, try again later!");
      }

      return { ...rest };
    },
    [authenticateFunction]
  );

  return { authenticateService };
};

export default useAuthService;
