import { useState } from "react";
import { isEmpty } from "lodash";
import classes from "./PrintLabel.module.scss";

import Title from "../../Common/Title";
import Paragraph from "../../Common/Paragraph";
import Button from "../../Common/Button";
import ReturnInstructionsModal from "../ReturnInstructionsModal";
import PrintLabelModal from "../PrintLabelModal";
import { PDFLabel, RMAData } from "../../../entities/RMAData";
import Skeleton from "@mui/material/Skeleton";

type PrintLabelProps = {
  rmaData: RMAData;
  pdfLabelData: PDFLabel | null;
  isLabelInfoCreating: boolean;
  isFetchingLabels: boolean;
};

const PrintLabel: React.FC<PrintLabelProps> = ({
  rmaData,
  pdfLabelData,
  isLabelInfoCreating,
  isFetchingLabels,
}) => {
  const [openReturnInstructions, setOpenReturnInstructions] = useState(false);
  const [openPrintLabel, setOpenPrintLabel] = useState(false);
  /*   const handleOpenReturnInstructions = () => setOpenReturnInstructions(true); */

  const pdfURL = "data:application/pdf;base64," + pdfLabelData?.base64URL;

  let formattedDueDate = "-";
  try {
    const dateCreated = !isEmpty(rmaData.datecreated)
      ? new Date(rmaData.datecreated)
      : null;
    if (dateCreated) {
      const dueDate = new Date(
        dateCreated.getTime() + 15 * 24 * 60 * 60 * 1000
      );
      const dateOptions = {
        month: "short",
        day: "numeric",
        year: "numeric",
      } as Intl.DateTimeFormatOptions;

      formattedDueDate = new Intl.DateTimeFormat("default", dateOptions).format(
        dueDate
      );
    }
  } catch (error) {
    console.error(error);
  }

  return (
    <section className={classes.printLabel}>
      {isFetchingLabels || isLabelInfoCreating ? (
        <div className={classes.skeletonWrapper}>
          <div className={classes.skeletonCol}>
            <Skeleton variant="rounded" className={classes.skeletonTitle} />
            <Skeleton variant="text" className={classes.skeletonParagraph} />
            <Skeleton variant="text" className={classes.skeletonParagraph} />
          </div>
          <div className={classes.skeletonCol}>
            <Skeleton variant="rounded" className={classes.skeletonButton} />
          </div>
        </div>
      ) : (
        <div className={classes.printLabelWrapper}>
          <div>
            <Title kind="h3" size="s">
              Print Carrier Label
            </Title>
            <Paragraph size="xxxs">
              Box and send your items before&nbsp;
              <span className={classes.dueDate}>{formattedDueDate}</span>.
            </Paragraph>
            {/*             <Paragraph size="xxxs" className={classes.quantity}>
              Need assistance?&nbsp;
              <span
                onClick={handleOpenReturnInstructions}
                className={classes.returnInstructionsButton}
              >
                View Instructions
              </span>
            </Paragraph> */}
          </div>
          <div>
            <Button
              onClick={() => setOpenPrintLabel(true)}
              kind="primary"
              className={classes.pdfButton}
            >
              Print Label (PDF)
            </Button>
          </div>
        </div>
      )}
      <ReturnInstructionsModal
        open={openReturnInstructions}
        onClose={() => setOpenReturnInstructions(false)}
      />
      <PrintLabelModal
        open={openPrintLabel}
        pdfURL={pdfURL}
        onClose={() => setOpenPrintLabel(false)}
      />
    </section>
  );
};

export default PrintLabel;
