import React, { ChangeEvent } from "react";

import classNames from "classnames";
import classes from "./ReturnReasonSelect.module.scss";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";

export type ReturnReasonValue = {
  custrecord_ucp_return_rreason_free_ship: string;
  custrecord_ucp_return_reason_suggestion: string;
  custrecord_ucp_reason_auth_manually: boolean;
  custrecord_ucp_handling_cost: string;
  custrecord_ucp_time_of_refund: string;
  custrecord_ucp_returns_keep_item: string;
  name: string;
  id: string;
};

interface SelectProps {
  options?: { label: string; value: {} }[];
  defaultValue?: string;
  id?: string;
  name?: string;
  label?: string;
  className?: string;
  onChange?: (id: string, value: ReturnReasonValue) => void;
}

const ReturnReasonSelect: React.FC<SelectProps> = ({
  options = [],
  defaultValue,
  id,
  name,
  label,
  className,
  onChange,
}) => {
  const handleChange = (event: ChangeEvent<HTMLSelectElement>) => {
    const newValue: ReturnReasonValue = JSON.parse(event.target.value);
    const newId = event.target.id.split("_")[1];
    if (onChange) {
      onChange(newId, newValue);
    }
  };

  const updatedOptions = [
    { label: "- Select An Option -", value: "" },
    ...options,
  ];

  return (
    <div className={classNames(classes.select, className)}>
      {label && <label>{label}</label>}
      <div className={classes.selectWrapper}>
        <select onChange={handleChange} id={id} name={name}>
          {updatedOptions.map((option) => (
            <option key={option.label} value={JSON.stringify(option.value)}>
              {option.label}
            </option>
          ))}
        </select>
        <FontAwesomeIcon icon={faChevronDown} />
      </div>
    </div>
  );
};

export default ReturnReasonSelect;
