import React, { useContext, useState } from "react";
import classes from "./ReturnStatusHeader.module.scss";
import Title from "../../Common/Title";
import ReturnInstructionsModal from "../ReturnInstructionsModal";
import { Steps } from "../../../enums/Steps";
import { Status } from "../../../enums/Status";
import Button from "../../Common/Button";
import { OrderContext } from "../../../providers/OrderProvider";
import { useMediaQuery } from "react-responsive";

type ReturnStatusHeaderProps = {
  status?: string;
  step: string;
  handleSetStep: (step: Steps) => void;
  currentStep: Steps;
};

const ReturnStatusHeader: React.FC<ReturnStatusHeaderProps> = ({
  status,
  step,
  handleSetStep,
  currentStep,
}) => {
  const { currentRma } = useContext(OrderContext);
  const [openReturnInstructions, setOpenReturnInstructions] = useState(false);
  const handleOpenReturnInstructions = () => {
    setOpenReturnInstructions(true);
  };
  const isMobile = useMediaQuery({
    query: `(max-width: ${classes.screenMd})`,
  });
  if (!status) {
    return null;
  }
  const statusMap: {
    [key: string]: { title: string; paragraphs: any };
  } = {
    default: {
      title: "Request Return",
      paragraphs: ["Add the details of your return below.", <>&nbsp;</>],
    },
    [Status.PendingRefund]: {
      title: "Your return has been approved.",
      paragraphs: [
        "Please print the return label to continue with the process.",
        <>
          Need assistance?
          <span
            className={classes.viewInstructions}
            onClick={handleOpenReturnInstructions}
          >
            &nbsp;View Instructions
          </span>
        </>,
      ],
    },
    [Status.PendingReceipt]: {
      title: "Your return has been approved.",
      paragraphs: [
        "Please print the return label to continue with the process.",
        <>
          Need assistance?
          <span
            className={classes.viewInstructions}
            onClick={handleOpenReturnInstructions}
          >
            &nbsp;View Instructions
          </span>
        </>,
      ],
    },
    [Status.PartiallyReceived]: {
      title: "Your refund has been made.",
      paragraphs: [
        "We’ve refunded the items of your refund.",
        "Need further assistance? Contact Support",
      ],
    },
    [Status.PartiallyReceivedPendingRefund]: {
      title: "Your return has been approved.",
      paragraphs: [
        "Please print the return label to continue with the process.",
        <>
          Need assistance?
          <span
            className={classes.viewInstructions}
            onClick={handleOpenReturnInstructions}
          >
            &nbsp;View Instructions
          </span>
        </>,
      ],
    },
    [Status.PendingApproval]: {
      title: "Return under review.",
      paragraphs: [
        "Our team is currently reviewing your return.",
        "Need to modify this return? please contact our customer service team.",
      ],
    },
    [Status.Refunded]: {
      title: "Your refund has been made.",
      paragraphs: [
        "We’ve refunded the items of your refund.",
        "Need further assistance? Contact Support",
      ],
    },
    [Status.Cancelled]: {
      title: "Return cancelled.",
      paragraphs: [
        "Your return has been cancelled.",
        "If you need further details or assistance, please contact our customer service team.",
      ],
    },
    [Status.Closed]: {
      title: "Return cancelled.",
      paragraphs: [
        "Your return has been cancelled.",
        "If you need further details or assistance, please contact our customer service team.",
      ],
    },
  };

  const showDefaultStatus = step === Steps.STEP1 || step === Steps.STEP2;

  const title = showDefaultStatus
    ? statusMap["default"]?.title
    : statusMap[status || "default"]?.title;
  const paragraphs = showDefaultStatus
    ? statusMap["default"]?.paragraphs
    : statusMap[status || "default"]?.paragraphs ?? [];

  return (
    <>
      <header className={classes.stepHeader}>
        <div>
          <Title kind="h1" size="l">
            {title}
          </Title>
          {paragraphs.map((paragraph: any, index: string) => (
            <p key={index}>{paragraph}</p>
          ))}
        </div>
        <div>
          {currentStep !== Steps.STEP3 && currentRma && (
            <Button
              kind="secondary"
              className={classes.createdReturnsButton}
              onClick={() => handleSetStep(Steps.STEP3)}
            >
              Check Your Returns
            </Button>
          )}
          {!isMobile && currentStep === Steps.STEP3 && (
            <Button
              kind="primary"
              className={classes.generateNewReturnButton}
              onClick={() => handleSetStep(Steps.STEP1)}
            >
              Generate New Return
            </Button>
          )}
        </div>
      </header>
      <ReturnInstructionsModal
        open={openReturnInstructions}
        onClose={() => setOpenReturnInstructions(false)}
      />
      {isMobile && currentStep === Steps.STEP3 && (
        <div className={classes.generateNewReturnWrapper}>
          <Button
            kind="primary"
            className={classes.generateNewReturnButton}
            onClick={() => handleSetStep(Steps.STEP1)}
          >
            Generate New Return
          </Button>
        </div>
      )}
    </>
  );
};

export default ReturnStatusHeader;
