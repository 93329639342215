import React, { FC, useEffect, useState } from "react";
import classNames from "classnames";
import { Popover, Typography } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleInfo } from "@fortawesome/free-solid-svg-icons";

import classes from "./RMASummary.module.scss";

import Title from "../../Common/Title";

import { formatAmount } from "../../../utils/formatter-utils";
import { RMAData } from "../../../entities/RMAData";
import useInvoiceData from "../../../hooks/useInvoiceData";
import { isEmpty } from "lodash";

type Item = {
  value: number | undefined;
  label: string;
};

type OrderSummaryPriceInfo = {
  itemCost: Item;
  discount: Item;
  taxes: Item;
  handlingFee: Item;
  estimatedRefund: Item;
};

type RMASummaryProps = {
  currentRma: RMAData;
  rmaItems: any[];
};

const RMASummary: FC<RMASummaryProps> = ({ currentRma, rmaItems }) => {
  const [returnSummaryPriceInfo, setRMASummaryPriceInfo] =
    useState<OrderSummaryPriceInfo | null>(null);

  const { nonAssociatedDiscountItems } = useInvoiceData();

  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);

  const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  useEffect(() => {
    const calculateTotals = () => {
      let itemDiscounts = 0;

      const sumOfPriceItems = rmaItems.reduce((acc, item) => {
        itemDiscounts = itemDiscounts + Math.abs(item.discount);
        return acc + Number(item.amount);
      }, 0);

      let discount = Number(nonAssociatedDiscountItems?.[0]?.amount ?? 0);
      discount = discount > 0 ? -discount : discount === 0 ? 0 : discount;

      const items = Math.abs(Number(sumOfPriceItems)) + Math.abs(discount);

      const taxes =
        Math.abs(Number(currentRma.taxtotal)) +
        currentRma.items.reduce((sum, item) => {
          if (
            isEmpty(item.taxitem) ||
            Math.abs(Number(currentRma.taxtotal)) > 0
          ) {
            return sum;
          }
          return (
            (sum + Math.abs(Number(item?.taxitem ?? 0))) *
            Math.abs(Number(item.quantity ?? 1))
          );
        }, 0);
      let handlingFee = Number(
        currentRma.items.find((item) => item.itemtype === "Payment")?.amount ??
          0
      );

      handlingFee = handlingFee === 0 ? 0 : handlingFee;

      const subtotal = Math.abs(Number(currentRma.total));

      return { items, discount, subtotal, taxes, handlingFee };
    };

    const discountType = nonAssociatedDiscountItems?.[0]?.itemName
      ? `(${nonAssociatedDiscountItems?.[0]?.itemName})`
      : "";

    const { discount, items, taxes, handlingFee } = calculateTotals();
    setRMASummaryPriceInfo({
      itemCost: { value: items, label: "Subtotal" },
      discount: {
        value: discount,
        label: `Discount ${discountType}`,
      },
      taxes: {
        value: Number(taxes),
        label: "Taxes",
      },
      handlingFee: {
        value: handlingFee,
        label: "Handling Fee",
      },
      estimatedRefund: {
        value: Math.abs(Number(currentRma.total)),
        label: "Estimated Refund:",
      },
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentRma]);

  return (
    <aside className={classes.returnSummary}>
      <Title kind="h2" size="s">
        Return Summary
      </Title>
      <div className={classes.refund}>
        {returnSummaryPriceInfo &&
          Object.values(returnSummaryPriceInfo).map((item, index) => {
            if (index === Object.values(returnSummaryPriceInfo).length - 1) {
              return null;
            }

            return (
              <div key={index} className={classes.refundRow}>
                <span>{item.label}</span>
                <span>{formatAmount(Number(item.value))}</span>
              </div>
            );
          })}
        <span className={classes.divider}></span>
        <div className={classNames(classes.refundRow, classes.estimatedRefund)}>
          <span>
            {returnSummaryPriceInfo?.estimatedRefund.label}
            <span
              aria-owns={open ? "refund-estimate-popover" : undefined}
              aria-haspopup="true"
              onMouseEnter={handlePopoverOpen}
              onMouseLeave={handlePopoverClose}
              className={classes.description}
            >
              &nbsp;
              <FontAwesomeIcon icon={faCircleInfo} />
            </span>
          </span>
          <Popover
            id="refund-estimate-popover"
            sx={{ pointerEvents: "none" }}
            open={open}
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "left",
            }}
            onClose={handlePopoverClose}
            disableRestoreFocus
          >
            <Typography
              className={classes.descriptionPopover}
              sx={{ p: 1, fontSize: "11px", maxWidth: "400px" }}
            >
              This is an estimated based on each's item's unit price. The actual
              refund amount is subject to applied discounts, tax and shipping
              costs, store return policy and item status.
            </Typography>
          </Popover>
          <span>
            {formatAmount(
              Number(returnSummaryPriceInfo?.estimatedRefund.value)
            )}
          </span>
        </div>
      </div>
    </aside>
  );
};

export default RMASummary;
