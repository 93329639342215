import ShipmentStatus from "../../../enums/ShipmentStatus";
import classes from "./StatusProgressCircle.module.scss";

type StatusProgressCircleProps = {
  status: ShipmentStatus;
};

const StatusProgressCircle: React.FC<StatusProgressCircleProps> = ({
  status,
}) => {
  return (
    <>
      <svg
        width="48"
        height="48"
        viewBox="0 0 48 48"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={classes.svgLine}
      >
        {status === ShipmentStatus.Pre_Transit && (
          <>
            <desc>Status Pre-Transit Icon</desc>
            <path
              d="M24.0002 0.880701C24.0002 0.394304 24.3946 -0.00168816 24.8807 0.0161532C27.7319 0.120833 30.5442 0.733218 33.1845 1.82689C36.0963 3.03301 38.742 4.80083 40.9706 7.02945C43.1992 9.25805 44.967 11.9038 46.1731 14.8156C47.2668 17.456 47.8792 20.2682 47.9838 23.1195C48.0017 23.6056 47.6057 24 47.1193 24C46.6329 24 46.2404 23.6055 46.2212 23.1195C46.1174 20.4996 45.551 17.9163 44.5458 15.4897C43.4282 12.7916 41.7902 10.34 39.7251 8.27495C37.6601 6.2099 35.2085 4.57181 32.5105 3.45422C30.0838 2.44908 27.5005 1.88265 24.8807 1.77884C24.3946 1.75958 24.0002 1.3671 24.0002 0.880701Z"
              fill="#E9ECEF"
              className={classes.line}
            />
          </>
        )}
        {status === ShipmentStatus.In_Transit && (
          <>
            <desc>Status In Transit Icon</desc>
            <path
              className={classes.line}
              d="M24 0.880701C24 0.394304 24.3945 -0.00168816 24.8805 0.0161494C29.3188 0.179056 33.6313 1.57089 37.3337 4.04473C41.2805 6.68188 44.3566 10.4302 46.1731 14.8156C47.9896 19.201 48.4649 24.0266 47.5388 28.6822C46.6128 33.3377 44.327 37.6141 40.9706 40.9706C37.6141 44.327 33.3377 46.6128 28.6822 47.5388C24.0266 48.4649 19.201 47.9896 14.8156 46.1731C10.4302 44.3566 6.68188 41.2805 4.04473 37.3337C1.57089 33.6313 0.179056 29.3188 0.0161534 24.8805C-0.00168403 24.3944 0.394308 24 0.880705 24C1.36711 24 1.75959 24.3945 1.77884 24.8805C1.94086 28.9703 3.22902 32.9424 5.50929 36.3551C7.95289 40.0122 11.4261 42.8626 15.4897 44.5458C19.5532 46.229 24.0247 46.6694 28.3385 45.8113C32.6524 44.9532 36.6149 42.8352 39.7251 39.7251C42.8352 36.6149 44.9532 32.6524 45.8113 28.3385C46.6694 24.0247 46.229 19.5532 44.5458 15.4897C42.8626 11.4261 40.0122 7.9529 36.3551 5.50929C32.9424 3.22901 28.9703 1.94086 24.8805 1.77884C24.3945 1.75958 24 1.3671 24 0.880701Z"
            />
          </>
        )}
        {status === ShipmentStatus.Out_For_Delivery && (
          <>
            <desc>Out For Delivery Icon</desc>
            <path
              d="M24 0.880701C24 0.394304 24.3946 -0.00168816 24.8807 0.0161456C30.926 0.237925 36.6777 2.73657 40.9706 7.02944C45.4714 11.5303 48 17.6348 48 24C48 30.3652 45.4714 36.4697 40.9706 40.9706C36.6777 45.2634 30.926 47.7621 24.8807 47.9839C24.3946 48.0017 24 47.6057 24 47.1193C24 46.6329 24.3946 46.2404 24.8806 46.2212C30.4586 46.0003 35.7625 43.6876 39.7251 39.7251C43.8956 35.5545 46.2386 29.898 46.2386 24C46.2386 18.102 43.8956 12.4455 39.7251 8.27494C35.7626 4.31243 30.4587 1.9997 24.8806 1.77883C24.3946 1.75959 24 1.3671 24 0.880701Z"
              fill="#E9ECEF"
              className={classes.line}
            />
          </>
        )}

        {status === ShipmentStatus.Delivered ||
          (status === ShipmentStatus.Available_For_Pickup && (
            <>
              <desc>Delivered Icon</desc>
              <path
                d="M2.09815e-06 24C3.25692e-06 10.7452 10.7452 -3.25692e-06 24 -2.09815e-06C37.2548 -9.39372e-07 48 10.7452 48 24C48 37.2548 37.2548 48 24 48C10.7452 48 9.39372e-07 37.2548 2.09815e-06 24ZM46.2386 24C46.2386 11.718 36.282 1.7614 24 1.7614C11.718 1.7614 1.76141 11.718 1.76141 24C1.76141 36.282 11.718 46.2386 24 46.2386C36.282 46.2386 46.2386 36.282 46.2386 24Z"
                fill="#38CB89"
              />
            </>
          ))}
      </svg>
    </>
  );
};

export default StatusProgressCircle;
