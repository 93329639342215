import { FC } from "react";
import { Steps } from "../../../enums/Steps";
import classes from "./SubNavbar.module.scss";
import classNames from "classnames";

type SubNavbarProps = {
  step: Steps;
  handleSetStep: (step: Steps) => void;
};

const SubNavbar: FC<SubNavbarProps> = ({ step, handleSetStep }) => {
  const isActive = (currentStep: Steps) => {
    return step === currentStep || step > currentStep
      ? classes.active
      : classes.not;
  };

  return (
    <nav className={classes.subNavbar}>
      <div className={classNames(classes.subNavbarNav)}>
        <span
          className={classNames(classes.progressBar, classes?.[step])}
        ></span>
        <div
          onClick={() => handleSetStep(Steps.STEP1)}
          className={classNames(isActive(Steps.STEP1), classes.firstStep)}
        >
          <span>Select Items</span>
        </div>
        <div className={isActive(Steps.STEP2)}>
          <span>Return Details</span>
        </div>
        <div className={isActive(Steps.STEP3)}>
          <span>Return Status</span>
        </div>
      </div>
    </nav>
  );
};

export default SubNavbar;
