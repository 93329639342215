import React, { useState } from "react";
import classNames from "classnames";
import classes from "../ContactSupportModal/ContactSupportModal.module.scss";

import Modal from "@mui/material/Modal";

import Title from "../../Common/Title";
import Button from "../../Common/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";
import { CircularProgress } from "@mui/material";
import Textarea from "../../Common/Textarea";
import useContactSupport from "../../../services/useContactSupport";
import usePortalLookAndFeel from "../../../hooks/usePortalLookAndFeel";

type ContactSupportModalProps = {
  open: boolean;
  onClose: () => void;
  rmaId: string;
};

const ContactSupportModal: React.FC<ContactSupportModalProps> = ({
  open,
  onClose,
  rmaId,
}) => {
  const [additionalComments, setAdditionalComments] = useState("");

  const { portalLookAndFeel } = usePortalLookAndFeel();

  const { supportEmail } = portalLookAndFeel;

  const { getContactInformationForm, isSendingContactInfo } =
    useContactSupport();

  const sendSupportEmail = async () => {
    const body = {
      title: `RMA #${rmaId} does not have an associated label`,
      additionalComments,
      supportEmail,
    };
    const result = await getContactInformationForm(body);
    if (result) {
      setAdditionalComments("");
      onClose();
    }
  };

  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <div className={classNames(classes.modal, classes.returnInstructions)}>
        <FontAwesomeIcon icon={faEnvelope} className={classes.iconTruck} />
        <Title kind="h3" size="l">
          Contact Support
        </Title>
        <label htmlFor="">Additional Comments:</label>
        <Textarea
          onChange={(e) => setAdditionalComments(e.target.value)}
        ></Textarea>
        <Button
          kind="primary"
          className={classes.closeModalButton}
          onClick={sendSupportEmail}
          disabled={isSendingContactInfo}
        >
          {isSendingContactInfo ? (
            <CircularProgress
              className={classes.circularProgress}
              size="1rem"
              sx={{ color: "white" }}
            />
          ) : (
            <span>Send</span>
          )}
        </Button>
        <Button
          kind="secondary"
          className={classes.closeModalButton}
          onClick={onClose}
        >
          Close
        </Button>
      </div>
    </Modal>
  );
};

export default ContactSupportModal;
