import { Settings } from "../entities/InvoiceInfo";

const useSettingsData = () => {
  const settings: Settings | null = sessionStorage.getItem("settings")
    ? JSON.parse(sessionStorage.getItem("settings") as string)
    : null;

  return settings;
};

export default useSettingsData;
