import React from "react";
import classes from "./Image.module.scss";
interface ImageProps {
  src: string;
  alt: string;
  width?: number;
  height?: number;
}

const Image: React.FC<ImageProps> = ({ src, alt, width, height }) => {
  return (
    <>
      <img
        className={classes.image}
        src={src}
        alt={alt}
        width={width}
        height={height}
      />
    </>
  );
};

export default Image;
