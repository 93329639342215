import React, { useState } from "react";
import classes from "./ViewInstructions.module.scss";
import Title from "../../Common/Title";
import Paragraph from "../../Common/Paragraph";
import Button from "../../Common/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTruck } from "@fortawesome/free-solid-svg-icons";
import Modal from "@mui/material/Modal";
import ReturnInstructionsModal from "../ReturnInstructionsModal";
import usePortalLookAndFeel from "../../../hooks/usePortalLookAndFeel";

type ViewInstructionsProps = {
  returnInstructions: string | null; // HTML
};

const ViewInstructions: React.FC<ViewInstructionsProps> = ({
  returnInstructions,
}) => {
  const { portalLookAndFeel } = usePortalLookAndFeel();
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <section className={classes.labelNotCreated}>
      <div className={classes.labelNotCreatedWrapper}>
        <div>
          <Title kind="h3" size="s">
            <FontAwesomeIcon icon={faTruck} />
            {portalLookAndFeel.viewInstructionsTitle || "View Instructions"}
          </Title>
          <Paragraph size="xxxs">
            {portalLookAndFeel.viewInstructionsTitle ||
              "If this problem persists, please contact support."}
          </Paragraph>
        </div>
        <div>
          <Button
            onClick={handleOpen}
            kind="primary"
            className={classes.pdfButton}
          >
            View Instructions
          </Button>
        </div>
      </div>

      {returnInstructions ? (
        <Modal open={open} onClose={handleClose}>
          <div className={classes.modal}>
            <Title kind="h3" size="m">
              Instructions
            </Title>
            <div
              className={classes.instructionsContent}
              dangerouslySetInnerHTML={{
                __html: returnInstructions ?? "",
              }}
            />
            <Button
              onClick={handleClose}
              kind="secondary"
              className={classes.closeModalButton}
            >
              Close
            </Button>
          </div>
        </Modal>
      ) : (
        <ReturnInstructionsModal open={open} onClose={handleClose} />
      )}
    </section>
  );
};

export default ViewInstructions;
