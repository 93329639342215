import React, { FC, useContext, useEffect, useState } from "react";
import classNames from "classnames";
import { isEmpty } from "lodash";
import { Popover, Typography } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleInfo } from "@fortawesome/free-solid-svg-icons";

import classes from "./ReturnSummary.module.scss";

import Title from "../../Common/Title";

import { OrderContext } from "../../../providers/OrderProvider";
import { formatAmount } from "../../../utils/formatter-utils";
import { Acc } from "../../../pages/ReturnsPage/steps/Step2";
import useInvoiceData from "../../../hooks/useInvoiceData";
import { itemKey } from "../../../utils/item-utils";

type Item = {
  value: number | undefined;
  label: string;
};

type OrderSummaryPriceInfo = {
  itemCost: Item;
  discount: Item;
  taxes: Item;
  handlingFee: Item;
  estimatedRefund: Item;
};

type ReturnSummaryProps = {
  handlingFee: number;
  quantities: Acc;
};

const ReturnSummary: FC<ReturnSummaryProps> = ({ handlingFee, quantities }) => {
  const [returnSummaryPriceInfo, setReturnSummaryPriceInfo] =
    useState<OrderSummaryPriceInfo | null>(null);
  const { selectedProductsToReturn } = useContext(OrderContext);

  const { summaryInfo, nonAssociatedDiscountItems } = useInvoiceData();

  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);

  const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  useEffect(() => {
    const calculateTotals = () => {
      const itemsWithDiscount = selectedProductsToReturn?.map((order) => {
        const price =
          (order.price / Number(order.quantity)) *
          (quantities[order?.[itemKey]] ?? 0);
        const discount = order.discount * (quantities[order?.[itemKey]] ?? 0);
        // const discountPercentage = (Math.abs(discount) / price) * 100;
        const taxForItem =
          Number(order.etail_tax_item ?? 0) *
          (quantities[order?.[itemKey]] ?? 0);
        const taxForItemAfterDiscount = taxForItem; // - (discountPercentage / 100) * taxForItem;
        return { ...order, price, discount, taxForItemAfterDiscount };
      });

      const sumOfPriceItems = itemsWithDiscount?.reduce((acc, item) => {
        return acc + item.price;
      }, 0);

      /*       const sumOfDiscountItems = itemsWithDiscount?.reduce((acc, item) => {
        return acc + item.discount;
      }, 0); */

      const sumOfTaxItems = itemsWithDiscount?.reduce((acc, item) => {
        return acc + item.taxForItemAfterDiscount;
      }, 0);

      let discount = Number(nonAssociatedDiscountItems?.[0]?.amount ?? 0);
      discount = discount > 0 ? -discount : discount === 0 ? 0 : discount;
      const taxes = sumOfTaxItems;
      const items = sumOfPriceItems;

      const subtotal = items + taxes + Number(handlingFee ?? 0) + discount;

      return { items, discount, subtotal, taxes };
    };

    const discountType = nonAssociatedDiscountItems?.[0]?.itemName
      ? `(${nonAssociatedDiscountItems?.[0]?.itemName})`
      : "";

    if (!isEmpty(summaryInfo) && !isEmpty(selectedProductsToReturn)) {
      const { discount, items, subtotal, taxes } = calculateTotals();
      setReturnSummaryPriceInfo({
        itemCost: { value: items + Math.abs(discount), label: "Subtotal" },
        discount: {
          value: discount,
          label: `Discount ${discountType}`,
        },
        taxes: {
          value: Number(taxes),
          label: "Taxes",
        },
        handlingFee: {
          value: handlingFee === 0 ? 0 : Number(handlingFee ?? 0),
          label: "Handling Fee",
        },
        estimatedRefund: {
          value: subtotal,
          label: "Estimated Refund:",
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [handlingFee, quantities]);

  return (
    <aside className={classes.returnSummary}>
      <Title kind="h2" size="s">
        Return Summary
      </Title>
      <div className={classes.refund}>
        {returnSummaryPriceInfo &&
          Object.values(returnSummaryPriceInfo).map((item, index) => {
            if (index === Object.values(returnSummaryPriceInfo).length - 1) {
              return null;
            }

            return (
              <div key={index} className={classes.refundRow}>
                <span>{item.label}</span>
                <span>{formatAmount(Number(item.value))}</span>
              </div>
            );
          })}
        <span className={classes.divider}></span>
        <div className={classNames(classes.refundRow, classes.estimatedRefund)}>
          <span>
            {returnSummaryPriceInfo?.estimatedRefund.label}
            <span
              aria-owns={open ? "refund-estimate-popover" : undefined}
              aria-haspopup="true"
              onMouseEnter={handlePopoverOpen}
              onMouseLeave={handlePopoverClose}
              className={classes.description}
            >
              &nbsp;
              <FontAwesomeIcon icon={faCircleInfo} />
            </span>
          </span>
          <Popover
            id="refund-estimate-popover"
            sx={{ pointerEvents: "none" }}
            open={open}
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "left",
            }}
            onClose={handlePopoverClose}
            disableRestoreFocus
          >
            <Typography
              className={classes.descriptionPopover}
              sx={{ p: 1, fontSize: "11px", maxWidth: "400px" }}
            >
              This is an estimated based on each's item's unit price. The actual
              refund amount is subject to applied discounts, tax and shipping
              costs, store return policy and item status.
            </Typography>
          </Popover>
          <span>
            {formatAmount(
              Number(returnSummaryPriceInfo?.estimatedRefund.value)
            )}
          </span>
        </div>
      </div>
    </aside>
  );
};

export default ReturnSummary;
