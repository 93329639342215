import { Customer, Invoice, Item, Summary } from "../entities/InvoiceInfo";

const useInvoiceData = () => {
  const customerInfo: Customer | null = sessionStorage.getItem("customerInfo")
    ? JSON.parse(sessionStorage.getItem("customerInfo") as string)
    : null;

  const summaryInfo: Summary | null = sessionStorage.getItem("summaryInfo")
    ? JSON.parse(sessionStorage.getItem("summaryInfo") as string)
    : null;

  const discountInfo: number = sessionStorage.getItem("discountInfo")
    ? JSON.parse(sessionStorage.getItem("discountInfo") as string)
    : 0;

  const invoice: Invoice | null = sessionStorage.getItem("invoice")
    ? JSON.parse(sessionStorage.getItem("invoice") as string)
    : null;

  const nonAssociatedDiscountItems: Array<Item> = sessionStorage.getItem(
    "nonAssociatedDiscountItems"
  )
    ? JSON.parse(sessionStorage.getItem("nonAssociatedDiscountItems") as string)
    : null;

  return {
    customerInfo,
    summaryInfo,
    discountInfo,
    invoice,
    nonAssociatedDiscountItems,
  };
};

export default useInvoiceData;
