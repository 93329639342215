import { useCallback } from "react";

import useNetSuiteService from "./useNetSuiteService";

export type ReturnReasons = {
  name: string;
  custrecord_ucp_return_reason_suggestion: string;
  custrecord_ucp_return_rreason_free_ship: string;
  custrecord_ucp_reason_auth_manually: string;
  custrecord_ucp_time_of_refund: string;
  custrecord_ucp_handling_cost: string;
  custrecord_ucp_returns_keep_item: string;
};

const useReturnReasonService = () => {
  const { getFromNetSuite } = useNetSuiteService<ReturnReasons>({
    resource: "customrecord_ucp_return_reason",
  });

  const getReturnReason = useCallback(async () => {
    try {
      const response = await getFromNetSuite({});
      return response.results;
    } catch (error) {
      console.error(error);
    }
  }, [getFromNetSuite]);

  return { getReturnReason };
};

export default useReturnReasonService;
