import classes from "./ReturnDetails.module.scss";

import CustomerInfo from "../CustomerInfo";

import Title from "../../Common/Title";
import Paragraph from "../../Common/Paragraph";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCreditCard, faGift } from "@fortawesome/free-solid-svg-icons";
import { RMAData } from "../../../entities/RMAData";

type ReturnDetailsProps = {
  rmaData: RMAData;
};

const ReturnDetails: React.FC<ReturnDetailsProps> = ({ rmaData }) => {
  return (
    <section className={classes.returnDetails}>
      <header>
        <Title kind="h3" size="s">
          Return Details
        </Title>
        <Paragraph size="xxxs">
          Return Number&nbsp;<span>#{rmaData.id}</span>
        </Paragraph>
      </header>
      <span className={classes.divider}></span>
      <div className={classes.returnDetailsContent}>
        <div>
          <CustomerInfo />
        </div>
        <div>
          <div className={classes.returnDetailsPayment}>
            <div className={classes.creditCard}>
              <span>
                {rmaData.custbody_ucp_refund_method === "Credit" ? (
                  <FontAwesomeIcon
                    icon={faGift}
                    className={classes.creditCardIcon}
                  />
                ) : (
                  <FontAwesomeIcon
                    icon={faCreditCard}
                    className={classes.creditCardIcon}
                  />
                )}
                &nbsp;&nbsp;&nbsp;
              </span>
              <span>
                Refund Method:&nbsp;
                <span>{rmaData.custbody_ucp_refund_method}</span>
              </span>
            </div>
            <Paragraph size="xxxs">
              Estimated refund:&nbsp;
              <span>${Math.abs(parseInt(rmaData.total))}</span>
            </Paragraph>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ReturnDetails;
