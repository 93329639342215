import { useState } from "react";
import classes from "./ItemDetailsAccordion.module.scss";

import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMinus } from "@fortawesome/free-solid-svg-icons";
import { faPlus } from "@fortawesome/free-solid-svg-icons";

import Paragraph from "../../Common/Paragraph";
import { Steps } from "../../../enums/Steps";

type ItemDetailsAccordionProps = {
  product: any;
  currentStep: any;
};

const ItemDetailsAccordion: React.FC<ItemDetailsAccordionProps> = ({
  product,
  currentStep,
}) => {
  const [expandedAccordion, setExpandedAccordion] = useState<string | false>(
    false
  );

  const handleAccordionChange =
    (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpandedAccordion(isExpanded ? panel : false);
    };

  const toggleAccordionIcon = (productId: string) => {
    if (expandedAccordion === productId) {
      return <FontAwesomeIcon icon={faMinus} />;
    } else {
      return <FontAwesomeIcon icon={faPlus} />;
    }
  };
  return (
    <div className={classes.itemDetails}>
      <Accordion
        sx={{
          boxShadow: "none",
          border: "1px solid #E5E7EB",
          marginTop: "1rem !important",
          fontSize: ".75rem",
          fontWeight: "600",
          borderRadius: "4px",
        }}
        onChange={handleAccordionChange(product.id)}
      >
        <AccordionSummary
          aria-controls={product.id}
          id={product.id}
          sx={{
            height: "40px",
            minHeight: "auto !important",
          }}
        >
          <div className={classes.accordionSummaryHeader}>
            Item Details
            <div>{toggleAccordionIcon(product.id)}</div>
          </div>
        </AccordionSummary>
        <AccordionDetails
          sx={{
            padding: "1rem",
          }}
        >
          <div className={classes.accordionSummaryContent}>
            <Paragraph size="xxxs" className={classes.quantity}>
              <span>Quantity:</span>&nbsp;
              {Math.abs(product?.quantity)}
            </Paragraph>
            <Paragraph size="xxs" className={classes.price}>
              $&nbsp;
              {currentStep === Steps.STEP1 || currentStep === Steps.STEP2
                ? Math.abs(product?.price)
                : Math.abs(product?.amount)}
            </Paragraph>
          </div>
        </AccordionDetails>
      </Accordion>
    </div>
  );
};

export default ItemDetailsAccordion;
