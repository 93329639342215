import { FC, ReactNode } from "react";
import { Helmet, HelmetProvider as HelmetContext } from "react-helmet-async";
import usePortalLookAndFeel from "../../hooks/usePortalLookAndFeel";

type HelmetProviderType = {
  children: ReactNode;
};

const HelmetProvider: FC<HelmetProviderType> = ({ children }) => {
  const { portalLookAndFeel } = usePortalLookAndFeel();

  return (
    <HelmetContext>
      <Helmet>
        <link rel="icon" href={portalLookAndFeel?.favicon} />
        <title>{portalLookAndFeel?.title}</title>
        <meta name="description" content={portalLookAndFeel.slogan} />
        <link
          rel="preconnect"
          href="https://fonts.googleapis.com"
          crossOrigin=""
        />
        <link
          href="https://fonts.googleapis.com/css2?family=Manrope:wght@300;400;500;600;700;800&display=swap"
          rel="stylesheet"
        />
      </Helmet>
      {children}
    </HelmetContext>
  );
};

export default HelmetProvider;
