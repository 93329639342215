import { ReactNode } from "react";

import HelmetProvider from "../HelmetProvider";
import PortalLookAndFeelProvider from "../PortalLookAndFeelProvider";
import ToastProvider from "../ToastProvider";
import { OrderProvider } from "../OrderProvider";

const RootProvider = ({ children }: { children: ReactNode }) => (
  <PortalLookAndFeelProvider>
    <HelmetProvider>
      <ToastProvider>
        <OrderProvider>{children}</OrderProvider>
      </ToastProvider>
    </HelmetProvider>
  </PortalLookAndFeelProvider>
);

export default RootProvider;
