import { httpsCallable, HttpsCallable } from "firebase/functions";
import { useCallback, useMemo } from "react";

import useFunctionsService from "./useFunctionsService";
import useAuth from "../hooks/useAuth";
import { RMADataResults } from "../entities/RMAData";

const useGetRMAInformation = () => {
  const { functions } = useFunctionsService();
  const { credential } = useAuth();
  const token = useMemo(() => credential?.token, [credential]);

  const getRMAInformationFormFunction: HttpsCallable<
    {
      token: string | undefined;
      orderNumber: string;
      zipCode: string;
      domain: string;
    },
    RMADataResults
  > = useMemo(
    () => httpsCallable(functions, "unlockreturns-getRMAInformation"),
    [functions]
  );

  const getRMAInformationForm = useCallback(
    async (statusOrderForm: {
      orderNumber: string;
      zipCode: string;
      domain: string;
    }) => {
      try {
        const result = await getRMAInformationFormFunction({
          token,
          ...statusOrderForm,
        });

        return result.data;
      } catch (e) {
        console.error(e);
        throw new Error(
          "Unexpected error while authenticating, try again later!"
        );
      }
    },
    [token, getRMAInformationFormFunction]
  );
  return { getRMAInformationForm };
};

export default useGetRMAInformation;
