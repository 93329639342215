import { useState } from "react";
import classes from "./LabelNotCreated.module.scss";

import Title from "../../Common/Title";
import Paragraph from "../../Common/Paragraph";
import Button from "../../Common/Button";
import ContactSupportModal from "../ContactSupportModal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faWarning } from "@fortawesome/free-solid-svg-icons";
import usePortalLookAndFeel from "../../../hooks/usePortalLookAndFeel";

type LabelNotCreatedProps = {
  rmaId: string;
};

const LabelNotCreated: React.FC<LabelNotCreatedProps> = ({ rmaId }) => {
  const [openContactSupportModal, setOpenContactSupportModal] = useState(false);

  const { portalLookAndFeel } = usePortalLookAndFeel();

  const { labelNotCreatedTitle, labelNotCreatedSub } = portalLookAndFeel;

  return (
    <section className={classes.labelNotCreated}>
      <div className={classes.labelNotCreatedWrapper}>
        <div>
          <Title kind="h3" size="s">
            <FontAwesomeIcon icon={faWarning} />{" "}
            {labelNotCreatedTitle ?? "The label is not created yet"}
          </Title>
          <Paragraph size="xxxs">
            {" "}
            {labelNotCreatedSub ??
              "If this problem persists please contact support"}
          </Paragraph>
        </div>
        <div>
          <Button
            onClick={() => setOpenContactSupportModal(true)}
            kind="primary"
            className={classes.pdfButton}
          >
            Contact Support
          </Button>
        </div>
      </div>
      <ContactSupportModal
        rmaId={rmaId}
        open={openContactSupportModal}
        onClose={() => setOpenContactSupportModal(false)}
      />
    </section>
  );
};

export default LabelNotCreated;
