import { FC, useContext, useEffect, useRef, useState } from "react";
import { useMediaQuery } from "react-responsive";

import { OrderContext } from "../../../../providers/OrderProvider";
import { Steps } from "../../../../enums/Steps";
import { isEmpty } from "lodash";

import classes from "../../ReturnsPage.module.scss";
import classNames from "classnames";

import Row from "../../../../components/Common/Row";
import Column from "../../../../components/Common/Column";
import Button from "../../../../components/Common/Button";
import Title from "../../../../components/Common/Title";
import Paragraph from "../../../../components/Common/Paragraph";
import OrderSummary from "../../../../components/Returns/OrderSummary";
import ReturnProduct from "../../../../components/Returns/ReturnProduct";

type Step1Props = {
  handleSetStep: (step: Steps) => void;
};

export const Step1: FC<Step1Props> = ({ handleSetStep }) => {
  const [error, setError] = useState("");

  const { currentOrders, selectedProductsToReturn } = useContext(OrderContext);

  const [anyErrorPresent, setAnyErrorPresent] = useState(false);
  const returnContentRef = useRef<HTMLDivElement | null>(null);

  const hasEligibleProduct = currentOrders.some(
    (product) => product.eligible === true
  );

  const noEligibleProduct = currentOrders.some((product) => !product.eligible);

  const onContinueStep = () => {
    if (isEmpty(selectedProductsToReturn)) {
      setError("Please select the items you would like to return.");
      setAnyErrorPresent(true);
      return;
    } else {
      setError("");
      setAnyErrorPresent(false);
    }
    handleSetStep(Steps.STEP2);
  };

  useEffect(() => {
    if (!isEmpty(selectedProductsToReturn)) {
      setError("");
      setAnyErrorPresent(false);
    }
  }, [selectedProductsToReturn]);

  const isMobile = useMediaQuery({
    query: `(max-width: ${classes.screenL})`,
  });

  useEffect(() => {
    if (isMobile) {
      if (anyErrorPresent && returnContentRef.current) {
        returnContentRef.current.scrollIntoView({ behavior: "smooth" });
      }
    }
  }, [anyErrorPresent, isMobile]);

  return (
    <Row>
      {/* Column Left */}
      <Column width={60}>
        <section className={classes.returnItems}>
          {/* Product Eligible */}
          {hasEligibleProduct && (
            <div className={classes.elegible}>
              <header>
                <Title kind="h2" size="s">
                  Available for Return
                </Title>
                <Paragraph size="xxs">
                  Refund amount will be calculated in the next step.
                </Paragraph>
              </header>
              <div
                ref={returnContentRef}
                className={classNames(
                  classes.returnContent,
                  `${error ? classes.error : ""}`
                )}
              >
                {currentOrders
                  .filter((product) => product?.eligible)
                  .map((product) => (
                    <ReturnProduct key={product.id} product={product} />
                  ))}
              </div>
              {isMobile && <small className={classes.stepError}>{error}</small>}
            </div>
          )}
          {noEligibleProduct && (
            <div className={classes.notElegible}>
              <header>
                <Title kind="h2" size="s" style={{ marginBottom: "0px" }}>
                  Not Eligible for Returns
                </Title>
              </header>
              <div className={classes.returnContent}>
                {currentOrders
                  .filter((product) => !product?.eligible)
                  .map((product) => (
                    <ReturnProduct product={product} />
                  ))}
              </div>
            </div>
          )}
        </section>
      </Column>
      {/* Column Right */}
      <Column width={40} className={classes.stickyContent}>
        <OrderSummary currentOrders={currentOrders} />
        <div className={classes.stepButtons}>
          <div className={classes.stepButtonsWrapper}>
            <Button
              onClick={onContinueStep}
              kind="primary"
              className={classes.primary}
            >
              Continue
            </Button>
          </div>
          {!isMobile && (
            <div className={classes.stepErrors}>
              <small className={classes.stepError}>{error}</small>
            </div>
          )}
        </div>
      </Column>
    </Row>
  );
};
