import { useCallback, useState } from "react";
import { useMediaQuery } from "react-responsive";
import classNames from "classnames";
import { v4 as uuidv4 } from "uuid";
import Drawer from "@mui/material/Drawer";

import classes from "./Header.module.scss";
import ExternalLink from "../ExternalLink";
import BurgerIcon from "../BurgerIcon";
import VectorIcon from "../VectorIcon";
import Path from "../../../enums/Path";
import Link from "../Link";
import usePortalLookAndFeel from "../../../hooks/usePortalLookAndFeel";
import LayoutContainer from "../LayoutContainer";

const Header = () => {
  const { portalLookAndFeel } = usePortalLookAndFeel();
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  const toggleDrawer = useCallback(
    (event: React.KeyboardEvent | React.MouseEvent) => {
      if (
        event.type === "keydown" &&
        ((event as React.KeyboardEvent).key === "Tab" ||
          (event as React.KeyboardEvent).key === "Shift")
      ) {
        return;
      }

      setIsDrawerOpen((prevState) => !prevState);
    },
    []
  );

  const isMobile = useMediaQuery({
    query: `(max-width: ${classes.screenXl})`,
  });

  return (
    <div
      className={classNames(classes.principal, {
        [classes.portalLookAndFeelBackground]:
          portalLookAndFeel.navbarBackground !== "",
      })}
    >
      <LayoutContainer>
        {!isMobile ? (
          <>
            <div className={classes.links}>
              <Link to={Path.Home}>
                <img
                  src={portalLookAndFeel.logo}
                  alt={`${portalLookAndFeel.companyName} logo`}
                />
              </Link>
              {portalLookAndFeel.headerLinks &&
                portalLookAndFeel.headerLinks.map((link) => (
                  <ExternalLink
                    key={uuidv4()}
                    className={classes.link}
                    to={link.url}
                    target="_blank"
                  >
                    {link.text}
                  </ExternalLink>
                ))}
            </div>
            <div className={classes.actions}>
              {portalLookAndFeel.yourAccount && (
                <ExternalLink
                  className={classes.login}
                  to={portalLookAndFeel.yourAccount}
                >
                  Log in
                </ExternalLink>
              )}
            </div>
          </>
        ) : (
          <>
            <div className={classes.links}>
              <Link to={Path.Home}>
                <img
                  src={portalLookAndFeel.logo}
                  alt={`${portalLookAndFeel.companyName} Logo`}
                />
              </Link>
              <button
                type="button"
                onClick={toggleDrawer}
                className={classes.burger}
              >
                <BurgerIcon />
              </button>
            </div>
            {isDrawerOpen ? (
              <>
                <Drawer
                  open={isDrawerOpen}
                  onClose={toggleDrawer}
                  anchor="right"
                >
                  <div className={classes.menuContainer}>
                    {portalLookAndFeel.headerLinks && (
                      <div className={classes.sites}>
                        {portalLookAndFeel.headerLinks.map((link) => (
                          <div>
                            <ExternalLink
                              key={uuidv4()}
                              className={classes.link}
                              to={link.url}
                            >
                              <div className={classes.site}>
                                {link.text}
                                <VectorIcon />
                              </div>
                            </ExternalLink>
                          </div>
                        ))}
                      </div>
                    )}
                    <div className={classes.auth}>
                      {portalLookAndFeel.yourAccount && (
                        <ExternalLink
                          className={classNames(classes.link, classes.login)}
                          to={portalLookAndFeel.yourAccount}
                        >
                          Log in
                        </ExternalLink>
                      )}
                    </div>
                  </div>
                </Drawer>
              </>
            ) : (
              <></>
            )}
          </>
        )}
      </LayoutContainer>
    </div>
  );
};

export default Header;
