import React from "react";
import classNames from "classnames";
import classes from "../ReturnInstructionsModal/ReturnInstructionsModal.module.scss";

import Modal from "@mui/material/Modal";

import Title from "../../Common/Title";
import Paragraph from "../../Common/Paragraph";
import Button from "../../Common/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTruck } from "@fortawesome/free-solid-svg-icons";

type ReturnInstructionsModalProps = {
  open: boolean;
  onClose: () => void;
};

const ReturnInstructionsModal: React.FC<ReturnInstructionsModalProps> = ({
  open,
  onClose,
}) => {
  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <div className={classNames(classes.modal, classes.returnInstructions)}>
        <FontAwesomeIcon icon={faTruck} className={classes.iconTruck} />
        <Title kind="h3" size="l">
          Return Instructions
        </Title>
        <ul>
          <li>
            <Title kind="h4" size="xs">
              Print your shipping label
            </Title>
            <Paragraph size="xxxs">
              If you are unable to print, please bring this to a carrier
              location for assistance.
            </Paragraph>
          </li>
          <li>
            <Title kind="h4" size="xs">
              Pack up your return
            </Title>
            <Paragraph size="xxxs">
              Place your item(s) and the packing slip inside a sturdy container,
              like the one your item(s) came in. Mark out any older addresses
              and bar codes. Tape up your package and affix your shipping label
              to the outside.
            </Paragraph>
          </li>
          <li>
            <Title kind="h4" size="xs">
              Ship it back to us
            </Title>
            <Paragraph size="xxxs">
              Drop off your package at a carrier location near you. Once it
              ships, use the link in your confirmation email to track its
              progress. To find your closest FedEx location, visit the FedEx
              Drop Off Locator or go to fedex.com
            </Paragraph>
          </li>
        </ul>
        <Button
          kind="secondary"
          className={classes.closeModalButton}
          onClick={onClose}
        >
          Close
        </Button>
      </div>
    </Modal>
  );
};

export default ReturnInstructionsModal;
