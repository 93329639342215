import { httpsCallable, HttpsCallable } from "firebase/functions";
import { useCallback, useMemo, useState } from "react";

import useFunctionsService from "./useFunctionsService";
import useAuth from "../hooks/useAuth";

const useGetLabelInformation = () => {
  const { functions } = useFunctionsService();
  const { credential } = useAuth();
  const token = useMemo(() => credential?.token, [credential]);
  const [isFetchingLabels, setIsFetchingLabels] = useState(false);

  const getLabelInfoFormFunction: HttpsCallable<
    {
      token: string | undefined;
      rmaID: string;
      domain: string;
    },
    any
  > = useMemo(
    () => httpsCallable(functions, "unlockreturns-getLabelInformationNetsuite"),
    [functions]
  );

  const getLabelInfoForm = useCallback(
    async (statusOrderForm: { rmaID: string; domain: string }) => {
      try {
        setIsFetchingLabels(true);
        const result = await getLabelInfoFormFunction({
          token,
          ...statusOrderForm,
        });

        return result.data;
      } catch (e) {
        console.error(e);
        throw new Error(
          "Unexpected error while authenticating, try again later!"
        );
      } finally {
        setIsFetchingLabels(false);
      }
    },
    [token, getLabelInfoFormFunction]
  );
  return { getLabelInfoForm, isFetchingLabels };
};

export default useGetLabelInformation;
